import React, { Component } from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBTypography,
    MDBCardOverlay
} from 'mdb-react-ui-kit';
import { Slide, Fade, Zoom } from 'react-reveal';
import QueueAnim from 'rc-queue-anim';
import ScrollAnim from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import 'rc-texty/assets/index.css';
import serviceBanner from '../../assets/banner3.png';
import '../service/service.css';
import {
    get_server_banner,
    get_server_base,
    get_server_sub1,
    get_server_sub2,
    get_server_sub3,
    get_server_sub4,
    get_server_copywriting
} from "../../axios/Api";
import { BASE_URL } from "../../axios/config";
import yuansheng from "../../assets/services/yuansheng.png";
import putong from "../../assets/services/putong.png";
import gaoji from "../../assets/services/gaoji.png";
import shendu from "../../assets/services/shendu.png";
import AIChat from "../home/AIChat";
const ScrollOverPack = ScrollAnim.OverPack;
export default class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            server_banner: '',
            server_base_img: '',
            sub1: [],
            sub1_head: '',
            sub1_headSub: '',
            ser_sub2: [],
            sub2_head: '',
            sub3: [],
            sub3_head: '',
            sub4: [],
            sub4_head: '',
            sub4_headSub: '',
            test: '',
            copywriting: ''
        }
    }
    componentDidMount() {
        this.getBanner();
        this.getBase();
        this.getSub1();
        this.getSub3();
        this.getSub2();
        this.getSub4();
        this.getCopyeriting();
        document.body.scrollIntoView();
    }
    componentDidUpdate(){
    }
    //获取横幅
    getBanner = async () => {
        get_server_banner().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    server_banner: BASE_URL + (res.data.data.image_src !== "" ? res.data.data.image_src : serviceBanner)
                })
            }
        }).catch(error => {

        })
    }
    //获取基本信息
    getBase = async () => {
        get_server_base().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    server_base_img: res.data.data.photo
                })
            }
        }).catch(error => {

        })
    }
    //获取第一段内容
    getSub1 = async () => {
        get_server_sub1().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    sub1: res.data.data,
                    sub1_head: res.data.data[0].name,
                    sub1_headSub: res.data.data[0].sub_name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第一段
    initSub1() {
        if (this.state.sub1.length > 0) {
            return (
                this.state.sub1.map((item, index) => {
                    return (
                        (
                            <Fade right key={index}>
                                <MDBCol className="p-2">
                                    <MDBCard className='h-100 server-obj'>
                                        <Slide top>
                                            <MDBCardImage
                                                src={BASE_URL + item.photo}
                                                alt=''
                                                position='top'
                                            />
                                        </Slide>
                                        <Slide bottom>
                                            <MDBCardBody className="home-card-body">
                                                <MDBTypography listUnStyled className='mb-0 home-card-dz-text'>
                                                    <li className='mb-1 live-clone-text'>
                                                        {item.content}
                                                    </li>
                                                </MDBTypography>
                                            </MDBCardBody>
                                        </Slide >
                                    </MDBCard>
                                </MDBCol>
                            </Fade>
                        )
                    )
                })
            )
        }
    }
    //获取第二段
    getSub2 = async () => {
        get_server_sub2().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    ser_sub2: res.data.data,
                    sub2_head: res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第二段
    initSub2() {
        if (this.state.ser_sub2.length > 0) {
            return (
                (
                    <>
                        <MDBCol md='4'>
                            <MDBRow>
                                <MDBCol md='12' className="p-2">
                                    <Slide bottom>
                                        <MDBCard background='light' className='text-white'>
                                            <MDBCardImage className="live-clone-card-img" overlay src={BASE_URL + (this.state.ser_sub2.length >= 1 ? this.state.ser_sub2[0].photo : "")} alt='...' />
                                            <MDBCardOverlay className="live-clone-card-text">
                                                <Slide bottom>
                                                    <MDBCardTitle className="live-clone-card-title">{this.state.ser_sub2.length >= 1 ? this.state.ser_sub2[0].title : "无数据"}</MDBCardTitle>
                                                </Slide>
                                                <Slide bottom>
                                                    <MDBCardText>
                                                        {this.state.ser_sub2.length >= 1 ? this.state.ser_sub2[0].content : "无数据"}
                                                    </MDBCardText>
                                                </Slide>
                                            </MDBCardOverlay>
                                        </MDBCard>
                                    </Slide>
                                </MDBCol>
                                <MDBCol md='12' className="p-2">
                                    <Slide bottom>
                                        <MDBCard background='light' className='text-white'>
                                            <MDBCardImage className="live-clone-card-img" overlay src={BASE_URL + (this.state.ser_sub2.length >= 2 ? this.state.ser_sub2[1].photo : "")} alt='...' />
                                            <MDBCardOverlay className="live-clone-card-text">
                                                <Slide bottom>
                                                    <MDBCardTitle className="live-clone-card-title">{this.state.ser_sub2.length >= 2 ? this.state.ser_sub2[1].title : "无数据"}</MDBCardTitle>
                                                </Slide>
                                                <Slide bottom>
                                                    <MDBCardText>
                                                        {this.state.ser_sub2.length >= 2 ? this.state.ser_sub2[1].content : "无数据"}
                                                    </MDBCardText>
                                                </Slide>
                                            </MDBCardOverlay>
                                        </MDBCard>
                                    </Slide>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol md='4'>
                            <Slide top>
                                <MDBCard>
                                    <MDBCardImage src={BASE_URL + (this.state.server_base_img === "" ? "" : this.state.server_base_img)} alt='...' position='top' />
                                </MDBCard>
                            </Slide>
                        </MDBCol>
                        <MDBCol md='4'>
                            <MDBRow>
                                <MDBCol md='12' className="p-2">
                                    <Slide bottom>
                                        <MDBCard background='light' className='text-white'>
                                            <MDBCardImage className="live-clone-card-img" overlay src={BASE_URL + (this.state.ser_sub2.length >= 3 ? this.state.ser_sub2[2].photo : "")} alt='...' />
                                            <MDBCardOverlay className="live-clone-card-text">
                                                <Slide bottom>
                                                    <MDBCardTitle className="live-clone-card-title">{this.state.ser_sub2.length >= 3 ? this.state.ser_sub2[2].title : "无数据"}</MDBCardTitle>
                                                </Slide>
                                                <Slide bottom>
                                                    <MDBCardText>
                                                        {this.state.ser_sub2.length >= 3 ? this.state.ser_sub2[2].content : "无数据"}
                                                    </MDBCardText>
                                                </Slide>
                                            </MDBCardOverlay>
                                        </MDBCard>
                                    </Slide>
                                </MDBCol>
                                <MDBCol md='12' className="p-2">
                                    <Slide bottom>
                                        <MDBCard background='light' className='text-white'>
                                            <MDBCardImage className="live-clone-card-img" overlay src={BASE_URL + (this.state.ser_sub2.length >= 4 ? this.state.ser_sub2[3].photo : "")} alt='...' />
                                            <MDBCardOverlay className="live-clone-card-text">
                                                <Slide bottom>
                                                    <MDBCardTitle className="live-clone-card-title">{this.state.ser_sub2.length >= 4 ? this.state.ser_sub2[3].title : "无数据"}</MDBCardTitle>
                                                </Slide>
                                                <Slide bottom>
                                                    <MDBCardText>
                                                        {this.state.ser_sub2.length >= 4 ? this.state.ser_sub2[3].content : "无数据"}
                                                    </MDBCardText>
                                                </Slide>
                                            </MDBCardOverlay>
                                        </MDBCard>
                                    </Slide>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </>
                )
            )
        }
    }
    //获取第三段内容
    getSub3 = async () => {
        get_server_sub3().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    sub3: res.data.data,
                    sub3_head: res.data.data[0].name
                })
            }
        }).catch(error => {

        })
    }
    //渲染第三段
    initSub3() {
        if (this.state.sub3.length > 0) {
            return (
                this.state.sub3.map((item, index) => {
                    return (
                        (
                            <Fade right key={index}>
                                <MDBCol className="p-2">
                                    <MDBCard className='h-100 server-obj'>
                                        <Slide top>
                                            <MDBCardImage
                                                src={BASE_URL + item.photo}
                                                alt=''
                                                position='top'
                                            />
                                        </Slide>
                                        <MDBCardBody className="home-card-body">
                                            <Slide top cascade>
                                                <MDBCardTitle>
                                                    {item.title}
                                                </MDBCardTitle>
                                            </Slide>
                                            <Slide bottom cascade>
                                                <MDBCardText className="ser-card-slide-text">
                                                    {item.content}
                                                </MDBCardText>
                                            </Slide >
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </Fade>
                        )
                    )
                })
            )
        }
    }
    //获取第四段
    getSub4 = async () => {
        get_server_sub4().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    sub4: res.data.data,
                    sub4_head: res.data.data[0].name,
                    sub4_headSub: res.data.data[0].sub_name,
                    test: res.data.data[0].original
                })
            }
        }).catch(error => {

        })
    }
    //获取第四段文案
    getCopyeriting = async () => {
        get_server_copywriting().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    copywriting: res.data.data.content
                })
            }
        }).catch(error => {

        })
    }
    //渲染第四段
    initSub4() {
        if (this.state.sub4.length > 0) {
            return (
                this.state.sub4.map((item, index) => {
                    return (
                        (
                            <MDBCol key={index} style={{ margin: '0 auto' }} md='10'>
                                <MDBRow className='row-cols-1 row-cols-md-4 voice-row'>
                                    <Slide bottom>
                                        <MDBCol className="voice-col">
                                            <MDBCard style={{ backgroundImage: `url(${yuansheng})`, objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', textAlign: 'center' }}>
                                                <MDBCardBody>
                                                    <MDBCardTitle style={{ color: '#fff' }}>
                                                        原声
                                                    </MDBCardTitle>
                                                    <audio onPlay={(e) => this.playV(e)} className="audio-style" src={BASE_URL + item.original} controls loop></audio>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </Slide>
                                    <Slide bottom>
                                        <MDBCol className="voice-col">
                                            <MDBCard style={{ backgroundImage: `url(${putong})`, objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', textAlign: 'center' }}>
                                                <MDBCardBody>
                                                    <MDBCardTitle style={{ color: '#fff' }}>
                                                        普通
                                                    </MDBCardTitle>
                                                    <audio onPlay={(e) => this.playV(e)} className="audio-style" src={BASE_URL + item.general} controls loop></audio>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </Slide>
                                    <Slide bottom>
                                        <MDBCol className="voice-col">
                                            <MDBCard style={{ backgroundImage: `url(${gaoji})`, objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', textAlign: 'center' }}>
                                                <MDBCardBody>
                                                    <MDBCardTitle style={{ color: '#fff' }}>
                                                        高级
                                                    </MDBCardTitle>
                                                    <audio onPlay={(e) => this.playV(e)} className="audio-style" src={BASE_URL + item.high} controls loop></audio>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </Slide>
                                    <Slide bottom>
                                        <MDBCol className="voice-col">
                                            <MDBCard style={{ backgroundImage: `url(${shendu})`, objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', textAlign: 'center' }}>
                                                <MDBCardBody>
                                                    <MDBCardTitle style={{ color: '#fff' }}>
                                                        深度
                                                    </MDBCardTitle>
                                                    <audio onPlay={(e) => this.playV(e)} className="audio-style" src={BASE_URL + item.depth} controls loop></audio>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </Slide>
                                </MDBRow>
                            </MDBCol>
                        )
                    )
                })
            )
        }
    }
    //控制音频播放
    playV(e) {
        //获取当前点击音频完整路径
        let str = e.target.attributes.src.textContent;
        //获取音频文件名
        let t = str.slice(str.lastIndexOf('/') + 1);

        //获取所以audio
        let audios = document.getElementsByTagName("audio");
        for(let i = 0; i < audios.length; i++){
            let au = audios[i].attributes.src.textContent
            let s = au.slice(au.lastIndexOf('/') + 1);
            //判断当前播放是否在audio范围内，不在则暂停
            if(t !== s){
                audios[i].pause();
            }
        }
    }
    render() {
        return (
            <>
                <MDBRow style={{ margin: '0' }}>
                    <MDBCol md={12} style={{ padding: '0' }}>
                        <img alt='...' src={this.state.server_banner} className="service-banner-img" style={{ width: '100%', minHeight: '210px', objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
                    </MDBCol>
                </MDBRow>
                <MDBContainer key="0" className="home-news mt-4 d-flex">
                    {/* <TweenOne className="tween-one" key="0" animation={{ opacity: 1 }}> */}
                    <MDBRow className="mb-4">
                        <MDBCol md="12">
                            <div className="title-wrapper">
                                <h1 name="title" style={{ color: '#314659' }}>
                                    <span>云数字人案例展示</span>
                                </h1>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    {/* </TweenOne> */}
                    <MDBRow className="g-0">
                        <MDBCol md='5' className="mt-2">
                            <MDBCard className="video-card videoCard" id="videoCard" style={{ background: 'transparent', boxShadow: 'none' }}>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='7' className="mt-2">
                            <MDBCard style={{ background: 'transparent',boxShadow: 'none' }}>
                                <AIChat></AIChat>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                {/* 形象定制 */}
                <ScrollOverPack
                    id="service"
                    className="service mt-4"
                    appear={false}
                >
                    <QueueAnim key="ser" leaveReverse type="bottom">
                        <MDBContainer key="ser0" className="service-container mt-4 d-flex mb-4">
                            <TweenOne className="tween-one" key="ser1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <Zoom bottom cascade>
                                            <div className="live-clone-title-wrapper mb-3">
                                                <h1 className="mb-3">
                                                    {this.state.sub1_head}
                                                </h1>
                                                <h6>
                                                    {this.state.sub1_headSub}
                                                </h6>
                                            </div>
                                        </Zoom>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow className='row-cols-1 row-cols-md-4 g-4'>
                                {this.initSub1()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 仿真直播，克隆另一个你 */}
                <ScrollOverPack
                    id="live-clone"
                    className="live-clone mt-4"
                    appear={false}
                >
                    <QueueAnim key="clone" leaveReverse type="bottom">
                        <MDBContainer key="clone0" className="home-news mt-3 d-flex">
                            <TweenOne className="tween-one" key="clone1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-2">
                                    <MDBCol md="12">
                                        <Zoom bottom cascade>
                                            <div className="live-clone-title-wrapper mb-5">
                                                <h1>
                                                    {this.state.sub2_head}
                                                </h1>
                                            </div>
                                        </Zoom>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow>
                                {this.initSub2()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 声音定制 */}
                <ScrollOverPack
                    id="service-obj"
                    className="service-obj mt-4"
                    style={{display:this.state.sub4.length > 0?'block':'none'}}
                    appear={false}
                >
                    <QueueAnim key="serobj" leaveReverse type="bottom">
                        <MDBContainer key="serobj0" className="service-container d-flex mb-5">
                            <TweenOne className="tween-one" key="serobj1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-3">
                                    <MDBCol md="12">
                                        <div className="live-clone-title-wrapper mb-3">
                                            <Slide bottom>
                                                <h1 className="mb-3">
                                                    {this.state.sub4_head === '' ? '' : this.state.sub4_head}
                                                </h1>
                                            </Slide>
                                            <Slide bottom>
                                                <h6 style={{ fontWeight: 'bold' }}>
                                                    {this.state.sub4_headSub === '' ? '' : this.state.sub4_headSub}
                                                </h6>
                                            </Slide>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow className='row-cols-1 row-cols-md-1'>
                                <MDBCol md='4' style={{ margin: '0 auto' }}>
                                    <Slide bottom>
                                        {this.state.copywriting}
                                    </Slide>
                                </MDBCol>
                                {this.initSub4()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
                {/* 服务对象 */}
                <ScrollOverPack
                    id="service-obj"
                    className="service-obj mt-4"
                    appear={false}
                >
                    <QueueAnim key="serobj" leaveReverse type="bottom">
                        <MDBContainer key="serobj0" className="service-container d-flex mb-4">
                            <TweenOne className="tween-one" key="serobj1" animation={{ opacity: 1 }}>
                                <MDBRow className="mb-4">
                                    <MDBCol md="12">
                                        <Zoom bottom cascade>
                                            <div className="live-clone-title-wrapper mb-3">
                                                <h1 className="mb-3">
                                                    {this.state.sub3_head}
                                                </h1>
                                            </div>
                                        </Zoom>
                                    </MDBCol>
                                </MDBRow>
                            </TweenOne>
                            <MDBRow className='row-cols-1 row-cols-md-4 g-4'>
                                {this.initSub3()}
                            </MDBRow>
                        </MDBContainer>
                    </QueueAnim>
                </ScrollOverPack>
            </>
        );
    }
}